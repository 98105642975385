












































import { reactive, toRefs, defineComponent } from '@vue/composition-api';
import { useAuthActions } from '@/store';

interface IState {
  password: string;
  msg: string;
  type: string;
  loading: boolean;
}

export default defineComponent({
  props: {
    token: {
      type: String,
      default: ''
    },
    tokenId: {
      type: String,
      default: ''
    }
  },

  setup(props, vm) {
    const state: IState = reactive({
      password: '',
      msg: '',
      type: 'success',
      loading: false
    });
    const { changePassword } = useAuthActions(['changePassword']);
    const submit = async () => {
      state.loading = true;
      try {
        await changePassword({
          email: vm.root.$route.query.username,
          password: state.password
        });
        state.msg = 'Your password has been changed';
        state.type = 'success';
        vm.root.$router.push({ name: 'login' });
      } catch (err) {
        state.msg = err;
        state.type = 'error';
      }
      state.loading = false;
    };

    return { ...toRefs(state), submit };
  }
});
